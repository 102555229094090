import { useRegisterEvents, useSigma } from "react-sigma-v2";
import { FC, useEffect } from "react";
import { getTargetUrl } from "../cluster-util";

function getMouseLayer() {
  return document.querySelector(".sigma-mouse");
}

const GraphEventsController: FC<{ setHoveredNode: (node: string | null) => void, source: string }> = ({ setHoveredNode, source, children }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();
  let lastNode: string | null = null;

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    let clickTimeId: NodeJS.Timeout | undefined;
    const waitTime = 100;
    registerEvents({
      clickNode({ node }) {
        clearTimeout(clickTimeId)
        clickTimeId = setTimeout(function() {
          if (node === lastNode) {
            setHoveredNode(null);
            lastNode = null
            return
          }
          setHoveredNode(node);
          lastNode = node
        }, waitTime)
      },
      doubleClickNode(e) {
        e.preventSigmaDefault()
        const node = e.node
        clearTimeout(clickTimeId)
        const label = graph.getNodeAttribute(node, "label")
        const targetUrl = getTargetUrl(source)
        const corpUrl = targetUrl + label
        window.open(corpUrl, "_blank");  
      },
      enterNode({ node }) {
        // setHoveredNode(node);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.add("mouse-pointer");
      },
      leaveNode() {
        // setHoveredNode(null);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.remove("mouse-pointer");
      },
      rightClick(e) {
        // 右键去除选中
        e.preventSigmaDefault()
        e.original.preventDefault()
        lastNode = null
        setHoveredNode(null);
      }
    });
  }, []);

  return <>{children}</>;
};

export default GraphEventsController;
