import React, { FC, useEffect, useMemo, useState } from "react";
import { useSigma } from "react-sigma-v2";
import { MdCategory } from "react-icons/md";
import { keyBy, mapValues, sortBy, values } from "lodash";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

import { FiltersState, CustomTag } from "../types";
import Panel from "./Panel";

const ShareholdingTagsPanel: FC<{
  shareholdingTags: CustomTag[] | null;
  filters: FiltersState;
  toggleTag: (shareholdingTag: string) => void;
  setTags: (shareholdingTags: Record<string, boolean>) => void;
}> = ({ shareholdingTags, filters, toggleTag, setTags }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  const nodesPerTag = useMemo(() => {
    const index: Record<string, number> = {};
    graph.forEachNode((_, { shareholdingTag }) => {
      if (!shareholdingTag) {
        return
      }
      index[shareholdingTag] = (index[shareholdingTag] || 0) + 1
    });
    return index;
  }, []);

  const maxNodesPerTag = useMemo(() => Math.max(...values(nodesPerTag)), [nodesPerTag]);
  const visibleTagsCount = useMemo(() => {
     if (filters.shareholdingTags) {
      return Object.keys(filters.shareholdingTags).length
     }
     return 0
  } , [filters]);

  const [visibleNodesPerTag, setVisibleNodesPerTag] = useState<Record<string, number>>(nodesPerTag);
  useEffect(() => {
    // To ensure the graphology instance has up to data "hidden" values for
    // nodes, we wait for next frame before reindexing. This won't matter in the
    // UX, because of the visible nodes bar width transition.
    requestAnimationFrame(() => {
      const index: Record<string, number> = {};
      graph.forEachNode((_, { shareholdingTag, mask }) => !mask && (index[shareholdingTag] = (index[shareholdingTag] || 0) + 1));
      setVisibleNodesPerTag(index);
    });
  }, [filters]);

  const sortedTags = useMemo(
    () => sortBy(shareholdingTags, (shareholdingTag) => (-nodesPerTag[shareholdingTag.key])),
    [shareholdingTags, nodesPerTag],
  );

  if (!shareholdingTags || !filters.shareholdingTags) {
    return null
  }
  
  return (
    <Panel
      title={
        <>
          <MdCategory className="text-muted" /> 企业投资占比
          {visibleTagsCount < shareholdingTags.length ? (
            <span className="text-muted text-small">
              {" "}
              ({visibleTagsCount} / {shareholdingTags.length})
            </span>
          ) : (
            ""
          )}
        </>
      }
    >
      <p>
        <i className="text-muted">点击类别标签在图谱中 显示/隐藏对应内容.</i>
      </p>
      <p className="buttons">
        <button className="btn" onClick={() => setTags(mapValues(keyBy(shareholdingTags, "key"), () => true))}>
          <AiOutlineCheckCircle /> Check all
        </button>{" "}
        <button className="btn" onClick={() => setTags({})}>
          <AiOutlineCloseCircle /> Uncheck all
        </button>
      </p>
      <ul>
        {sortedTags.map((tag) => {
          const nodesCount = nodesPerTag[tag.key];
          const visibleNodesCount = visibleNodesPerTag[tag.key] || 0;
          return (
            <li
              className="caption-row"
              key={tag.key}
              title={`${nodesCount} 家企业${
                visibleNodesCount !== nodesCount ? ` (${visibleNodesCount} 可见)` : ""
              }`}
            >
              <input
                type="checkbox"
                checked={filters.shareholdingTags[tag.key] || false}
                onChange={() => toggleTag(tag.key)}
                id={`tag-${tag.key}`}
              />
              <label htmlFor={`tag-${tag.key}`}>
                <span
                  className="circle"
                />{" "}
                <div className="node-label">
                  <span>{tag.label}</span>
                  <div className="bar" style={{ width: (100 * nodesCount) / maxNodesPerTag + "%" }}>
                    <div
                      className="inside-bar"
                      style={{
                        width: (100 * visibleNodesCount) / nodesCount + "%",
                      }}
                    />
                  </div>
                </div>
              </label>
            </li>
          );
        })}
      </ul>
    </Panel>
  );
};

export default ShareholdingTagsPanel;
