import { FC } from "react";
import { useSigma } from "react-sigma-v2";


const LabelThreshold: FC = () => {
    const sigma = useSigma();

    function handleInput(e: any) {
        const threshold = document.getElementById("labels-threshold") as HTMLInputElement;
        // console.log('v', threshold.value)
        sigma.setSetting("labelRenderedSizeThreshold", +threshold.value)

    }
    return (
        <div className="input">
            <input id="labels-threshold"
            type="range" min="0" max="15" step="0.1"
            onInput={ handleInput }
            title="label 密度设置"
            />
        </div>
    );
    }
export default LabelThreshold