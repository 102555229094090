import React, { FC, useEffect, useState } from "react";
import { useSigma } from "react-sigma-v2";
import { getTitleName } from "../cluster-util";

import { FiltersState } from "../types";

function prettyPercentage(val: number): string {
  return (val * 100).toFixed(1) + "%";
}

const GraphTitle: FC<{ filters: FiltersState, fileName: string }> = ({ filters, fileName }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  let title = '产业集群图谱'
  if (fileName) {
    title = getTitleName(fileName) 
  }
  

  const [visibleItems, setVisibleItems] = useState<{ nodes: number; edges: number }>({ nodes: 0, edges: 0 });
  useEffect(() => {
    // To ensure the graphology instance has up to data "hidden" values for
    // nodes, we wait for next frame before reindexing. This won't matter in the
    // UX, because of the visible nodes bar width transition.
    requestAnimationFrame(() => {
      const index = { nodes: 0, edges: 0 };
      graph.forEachNode((_, { mask }) => !mask && index.nodes++);
      graph.forEachEdge((_, _2, _3, _4, source, target) => !source.mask && !target.mask && index.edges++);
      setVisibleItems(index);
    });
  }, [filters]);

  return (
    <div className="graph-title">
      <h1>{ title }</h1>
      <h2>
        <i>
          {graph.order} 家企业{" "}
          {visibleItems.nodes !== graph.order
            ? ` (${prettyPercentage(visibleItems.nodes / graph.order)} 可见)`
            : ""}
          , {graph.size} 条边
          {" "}
          {visibleItems.edges !== graph.size
            ? ` (${prettyPercentage(visibleItems.edges / graph.size)} 可见)`
            : ""}
        </i>
      </h2>
    </div>
  );
};

export default GraphTitle;
