import { FC } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const ToggleLableBtn: FC<{ showLabel: boolean, 
    setShowLabel: (showLabel: boolean) => void 
    }> = ({ showLabel, setShowLabel }) => {
    function handleClick() {
        setShowLabel((() => !showLabel)())
    }

    if (showLabel) {
        return (
            <div className="ico">
                <button
                    type="button"
                    onClick={ handleClick }
                    title="显示/隐藏关联企业label"
                >
                <BsFillEyeFill />
                </button>
            </div>
        );
    }

    return (
        <div className="ico">
            <button
                type="button"
                onClick={ handleClick }
                title="显示/隐藏关联企业label"
            >
            <BsFillEyeSlashFill />
            </button>
        </div>
    );

};

export default ToggleLableBtn
