import React, { FC, useEffect, useState } from "react";
import { SigmaContainer, ZoomControl, FullScreenControl } from "react-sigma-v2";
import { omit, mapValues, keyBy, constant } from "lodash";
import { useSearchParams } from "react-router-dom";

// import getNodeProgramImage from "sigma/rendering/webgl/programs/node.image";

import GraphSettingsController from "./GraphSettingsController";
import GraphEventsController from "./GraphEventsController";
import GraphDataController from "./GraphDataController";
import DescriptionPanel from "./DescriptionPanel";
import { Dataset, FiltersState } from "../types";
import { defaultName, defaultSource } from "../cluster-util";
import ClustersPanel from "./ClustersPanel";
import SearchField from "./SearchField";
import drawLabel from "../canvas-utils";
import GraphTitle from "./GraphTitle";
import TagsPanel from "./TagsPanel";
import RecommendTagsPanel from "./RecommendTagsPanel";
import ShareholdingTagsPanel from "./ShareholdingTagsPanel";
import InvestTierTagsPanel from "./InvestTierTagsPanel";
import ProvinceTagsPanel from "./ProvinceTagsPanel";
import PotentialGiantTagsPanel from "./PotentialGiantTagsPanel";
import LabelThreshold from "./LabelThreshold";
import SavePngBtn from "./SavePngBtn";
import ToggleLableBtn from "./ToggleLabelBtn";

import "react-sigma-v2/lib/react-sigma-v2.css";
import { GrClose } from "react-icons/gr";
import { BiRadioCircleMarked, BiBookContent } from "react-icons/bi";
import { BsArrowsFullscreen, BsFullscreenExit, BsZoomIn, BsZoomOut } from "react-icons/bs";

const Root: FC = () => {
  const [showContents, setShowContents] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [dataset, setDataset] = useState<Dataset | null>(null);
  const [filtersState, setFiltersState] = useState<FiltersState>({
    clusters: {},
    tags: {},
    recommendTags: {},
    shareholdingTags: {},
    investTierTags: {},
    provinceTags: {},
    potentialGiantTags: {},
  });
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const [showLabel, setShowLabel] = useState<boolean>(false)
  const [searchParams] = useSearchParams();

  let fileName = searchParams.get('name')
  if (!fileName) {
    fileName = defaultName
  }

  let source = searchParams.get('source')
  if (!source) {
    source = defaultSource
  }

  const jsonFile = fileName + '.json'    
  // Load data on mount:
  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/${jsonFile}`, {
      headers: {
        'Cache-Control': 'no-cache'
      }
    }).then((res) => res.json())
      .then((dataset: Dataset) => {
        setDataset(dataset);
        setFiltersState({
          clusters: mapValues(keyBy(dataset.clusters, "key"), constant(true)),
          tags: mapValues(keyBy(dataset.tags, "key"), constant(true)),
          recommendTags: mapValues(keyBy(dataset.recommendTags, "key"), constant(true)),
          shareholdingTags: mapValues(keyBy(dataset.shareholdingTags, "key"), constant(true)),
          investTierTags: mapValues(keyBy(dataset.investTierTags, "key"), constant(true)),
          provinceTags: mapValues(keyBy(dataset.provinceTags, "key"), constant(true)),
          potentialGiantTags: mapValues(keyBy(dataset.potentialGiantTags, "key"), constant(true)),
        });
        requestAnimationFrame(() => setDataReady(true));
      });
  }, []);

  if (!dataset) return null;

  return (
    <div id="app-root" className={showContents ? "show-contents" : ""}>
      <SigmaContainer
        graphOptions={{ type: "directed" }} // directed, undirected
        initialSettings={{
         // nodeProgramClasses: { image: getNodeProgramImage() },
          labelRenderer: drawLabel,
          defaultNodeType: "circle", // "image", "circle"
          defaultEdgeType: "line", //"arrow",
          labelDensity: 0.07,
          labelGridCellSize: 60,
          labelRenderedSizeThreshold: 15, // 15, label threshold
          labelFont: "Lato, sans-serif",
          zIndex: true,
        }}
        className="react-sigma"
      >
        <GraphSettingsController hoveredNode={hoveredNode} showLabel={showLabel} />
        <GraphEventsController setHoveredNode={setHoveredNode} source={source} />
        <GraphDataController dataset={dataset} filters={filtersState} />

        {dataReady && (
          <>
            <div className="controls">
              <div className="ico">
                <button
                  type="button"
                  className="show-contents"
                  onClick={() => setShowContents(true)}
                  title="Show caption and description"
                >
                  <BiBookContent />
                </button>
              </div>
              <ToggleLableBtn showLabel={showLabel} setShowLabel={setShowLabel} />
              <FullScreenControl
                className="ico"
                customEnterFullScreen={<BsArrowsFullscreen />}
                customExitFullScreen={<BsFullscreenExit />}
              />
              <ZoomControl
                className="ico"
                customZoomIn={<BsZoomIn />}
                customZoomOut={<BsZoomOut />}
                customZoomCenter={<BiRadioCircleMarked />}
              />
              <SavePngBtn />
            </div>
            <div className="contents">
              <div className="ico">
                <button
                  type="button"
                  className="ico hide-contents"
                  onClick={() => setShowContents(false)}
                  title="Show caption and description"
                >
                  <GrClose />
                </button>
              </div>
              <GraphTitle filters={filtersState} fileName={fileName} />
              <div className="panels">
                <LabelThreshold />
                <SearchField filters={filtersState} />
                <DescriptionPanel fileName={fileName} />
                <ClustersPanel
                  clusters={dataset.clusters}
                  filters={filtersState}
                  setClusters={(clusters) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      clusters,
                    }))
                  }
                  toggleCluster={(cluster) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      clusters: filters.clusters[cluster]
                        ? omit(filters.clusters, cluster)
                        : { ...filters.clusters, [cluster]: true },
                    }));
                  }}
                />
                <TagsPanel
                  tags={dataset.tags}
                  filters={filtersState}
                  setTags={(tags) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      tags,
                    }))
                  }
                  toggleTag={(tag) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      tags: filters.tags[tag] ? omit(filters.tags, tag) : { ...filters.tags, [tag]: true },
                    }));
                  }}
                />
                <RecommendTagsPanel
                  recommendTags={dataset.recommendTags}
                  filters={filtersState}
                  setTags={(recommendTags) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      recommendTags,
                    }))
                  }
                  toggleTag={(recommendTag) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      recommendTags: filters.recommendTags[recommendTag] ? omit(filters.recommendTags, recommendTag) : { ...filters.recommendTags, [recommendTag]: true },
                    }));
                  }}
                />
                <ShareholdingTagsPanel
                  shareholdingTags={dataset.shareholdingTags}
                  filters={filtersState}
                  setTags={(shareholdingTags) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      shareholdingTags,
                    }))
                  }
                  toggleTag={(shareholdingTag) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      shareholdingTags: filters.shareholdingTags[shareholdingTag] ? omit(filters.shareholdingTags, shareholdingTag) : { ...filters.shareholdingTags, [shareholdingTag]: true },
                    }));
                  }}
                />
                <InvestTierTagsPanel
                  investTierTags={dataset.investTierTags}
                  filters={filtersState}
                  setTags={(investTierTags) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      investTierTags,
                    }))
                  }
                  toggleTag={(investTier) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      investTierTags: filters.investTierTags[investTier] ? omit(filters.investTierTags, investTier) : { ...filters.investTierTags, [investTier]: true },
                    }));
                  }}
                />
                <ProvinceTagsPanel
                  provinceTags={dataset.provinceTags}
                  filters={filtersState}
                  setTags={(provinceTags) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      provinceTags,
                    }))
                  }
                  toggleTag={(province) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      provinceTags: filters.provinceTags[province] ? omit(filters.provinceTags, province) : { ...filters.provinceTags, [province]: true },
                    }));
                  }}
                />
                <PotentialGiantTagsPanel
                  potentialGiantTags={dataset.potentialGiantTags}
                  filters={filtersState}
                  setTags={(potentialGiantTags) =>
                    setFiltersState((filters) => ({
                      ...filters,
                      potentialGiantTags,
                    }))
                  }
                  toggleTag={(potentialGiant) => {
                    setFiltersState((filters) => ({
                      ...filters,
                      potentialGiantTags: filters.potentialGiantTags[potentialGiant] ? omit(filters.potentialGiantTags, potentialGiant) : { ...filters.potentialGiantTags, [potentialGiant]: true },
                    }));
                  }}
                />
              </div>
            </div>
          </>
        )}
      </SigmaContainer>
    </div>
  );
};

export default Root;
