
export const defaultSource = 'vs'
export function getTargetUrl(name: string) {
  if (name === 'vs') {
    return 'https://venturesights.cn/#/corp/corp?corp='
  }

  if (name === 'ciec') {
    return 'https://ciec.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'cmaa') {
    return 'https://cmaa.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'seefund') {
    return 'https://seefund.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'casyq') {
    return 'https://casyq.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'nltown') {
    return 'https://nltown.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'inno') {
    return 'https://inno.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'gtjaiic') {
    return 'https://gtjaiic.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'zgcts') {
    return 'https://zgcts.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'mxt') {
    return 'https://mxt.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'aurora') {
    return 'https://aurora.venturesights.net/#/corp/corp?corp='
  }

  if (name === 'hexiang') {
    return 'https://zs.incostar.com/#/corp/corp?corp='
  }

  return 'https://venturesights.cn/#/corp/corp?corp='
}

export const defaultName = 'ningbo_all'

export function getTitleName(name: string) {
  switch (name) {
    case 'ningbo_all':
      return '宁波市产业集群图谱'
    case 'ningbo_2_1_medicine_food':
      return '宁波市生物医药/食品集群图谱'
    case 'rizhao_all':
      return '日照市产业集群图谱'
    case 'chengdu_all':
        return '成都市产业集群图谱'
    case 'wuhu_all':
      return '芜湖市产业集群图谱'
    case 'zhangjiagang_all':
      return '张家港市产业集群图谱'
    case 'chongqing_all':
      return '重庆市产业集群图谱'
    case 'hefei_all':
      return '合肥市产业集群图谱'
    case 'lincang_all':
      return '临沧市产业集群图谱'
    case 'xiamen_all':
      return '厦门市产业集群图谱'
    case 'shanghai_all':
      return '上海市产业集群图谱'
    case 'shenzhen_all':
      return '深圳市产业集群图谱'
    case 'yantai_all':
    case 'yantai_all_v2':
      return '烟台市产业集群图谱'
    case 'fuqing_all':
      return '福清市产业集群图谱'
    case 'special_nanhang_all':
      return '中国南方航空公司投资企业集群图谱'
    case 'yixing_all':
      return '宜兴市产业集群图谱'
    default:
      return '产业集群图谱'
  }
}

export function getDesc(name: string) {
  switch (name) {
    case 'ningbo_all':
      return [
        '在宁波市的产业集群图谱中, 较为清晰地看到九大类产业集群, 分别是:',
        '汽车及零配件/机械制造、智能家电/电子科技、石油化工/新材料/包装印刷、信息技术、新能源/光电科技/微电子、电气设备/基建工程与设计/建材、生物医药/食品、环保科技、纺织/服装以及多元化经营（其他）。'
      ]
    case 'ningbo_2_1_medicine_food':
      return [
        '宁波市生物医药/食品集群涉及企业450家，其中上市公司（包括三板）6家；基于模型，又可以细分为5个领域：生物医药、食品、农业科技、食品/环境检测机构和医疗设备；',
        '相较于其他集群网络指标的各类数据均偏低，本地企业之间竞争不明显，企业之间还没有形成比较好的生态；但过去24个月内专利增速和融资较快，表名集群内企业的技术实力正在快速提高，且具有一定的资本吸引力，其中获得资本的是生物医药。'
      ]
    case 'rizhao_all':
      return [
        '在日照市的产业集群图谱中, 较为清晰地看到日照市的十四大类产业集群，分别是:',
        '建筑工程/钢结构工程（26.35%）、医疗器械/医药科技（10.06%）、食品（9.99%）、农业（9.35%）、机械制造/汽车零部件（6.95%）、石油化工/钢铁/船舶港务/物流业/矿业（4.85%）、电气行业/新能源（4.46%）、新材料/建材（4.1%）、纺织业/木材/玻璃业（3.79%）、生物科技/环保科技（3.51%）、橡塑制品（3.24%）、建筑劳务（3.07%）、茶业（3.07%）以及信息技术（2.85%）。'
      ]
      case 'chengdu_all':
        return [
          '对成都市11,362家企业绘制产业集群图谱。为了便于计算和网络图呈现，仅选择成都市有专利，注册资本金大于¥500万，且和其它企业相似度大于70%的企业进入产业集群图谱。图谱中可以较为清晰地看到成都市的15大类产业集群，分别是:',
          '电子产品、信息技术、市政建设、环保科技、轨道交通/路桥建设/水利水电、生物医药、汽车/航空/机械制造、食品/农业、新材料、集成电路/通讯、电气设备/电力系统、石油、家居五金、酒业以及其他。'
        ]
      case 'wuhu_all':
        return [
          '对芜湖市3,786家企业绘制产业集群图谱，为了便于计算和网络图呈现，仅选择芜湖市有专利的企业进入产业集群图谱。图谱中较为清晰地看到芜湖市的九大类产业集群，分别是:',
          '智能装备、信息技术/电子科技、包装/新材料/建材、汽车及零配件、农业/食品、环保/新能源/光电科技、电线/电缆、基建/物流/航空航运、纺织/服装、以及其他。'
        ]
      case 'zhangjiagang_all':
        return [
          '对张家港6,810家企业绘制产业集群图谱,为了便于计算和网络图呈现，仅选择张家港市有专利的企业进入产业集群图谱。图中可以较为清晰地看到张家港市的九大类产业集群，分别是:',
          '金属材料及机械制造，新能源/环保/电力设备/光电，新材料/医药化工/运输物流/包装印刷/贸易进出口/建材，纺织服饰，日化/保健，建筑/装修，汽车零配件/玻璃，农业/水产/食品/园林，医疗器械，以及其他。'
        ]
      case 'chongqing_all':
        return [
          '对重庆市9,929家企业绘制产业集群图谱,为了便于计算和网络图呈现，仅选择重庆市有专利，注册资本金大于500万元的企业进入产业集群图谱。图谱中可以较为清晰地看到重庆市的17大类产业集群，分别是:',
          '汽车零部件/机械制造、电子产品/集成电路半导体、信息技术/智能硬件、市政建设、环保科技、生物医药、农业、新能源/仪器仪表、新材料/综合化工、建材、电气设备/电力系统、食品、铝业/玻璃业、木材/家具/安防、包装印刷、智能装备、养殖业以及其他'
        ]
        case 'hefei_all':
          return [
            '对合肥市8,584家企业绘制产业集群图谱,在合肥市产业集群图谱中，通过模型对企业做集群划分，识别出十四大产业集群，分别是:',
            '1）信息技术、2）电子科技/集成电路、3）市政建设、4）汽车零部件/机械制造、5）电气设备/电力系统、6）食品/农业、7）新材料/建材、8）能源/新能源、9）生物医药、10）环保科技、11）家具/日用品/安防、12）智能装备、13）文化创意、14）包装印刷以及其他'
          ]
        case 'lincang_all':
          return [
            '对临沧市781家企业绘制产业集群图谱，并利用模型对产业集群做自动化识别，图中可以较为清晰的看到临沧市的12大类产业集群，分别是:',
            '茶业（28.97%）、农产品种植业（14.87%）、工程建设（12.56%）、食品（7.69%）、饮品（5.26%）、医药与医疗器械（4.23%）、混凝土/水泥（3.72%）、商品贸易（3.72%）、制糖业（2.69%）、养殖业（2.05%）、电力系统（1.54%）、矿业（1.54%）以及其他'
          ]
          case 'xiamen_all':
            return [
              '对厦门市10,600家企业绘制产业集群图谱，在厦门市产业集群图谱中，通过模型对企业做集群划分，识别出十七大产业集群，分别是:',
              '1）电子科技/集成电路、2）信息技术、3）机械制造/金属制品/汽车及零部件、4）商品贸易、5）食品/农业/养殖业、6）工业贸易、7）生物医药、8）能源/新能源、9）包装印刷、10）环保科技、11）市政建设、12）智能装备、13）材料/新材料、14）光电科技、15）文化传媒、16）电气设备/电力系统、17）卫浴以及其他'
            ]
          case 'shanghai_all':
            return [
              '对上海市26,132家企业绘制产业集群图谱，并利用模型对产业集群做自动化识别，图中可以较为清晰地看到上海市可以大致分为14个产业，分别是:',
              '新一代信息技术、环保科技、房地产、大消费、新能源、电子/集成电路、生物科技、先进制造、电力、医疗器械、机械及自动化设备、智能显示技术及应用、钢结构建筑、消防、以及其它'
            ]
          case 'shenzhen_all':
            return [
              '通过对深圳市21,323家企业绘制产业集群图谱，并利用模型对产业集群做自动化识别,图中可以较为清晰地看到深圳市可以大致分为16个小的业务领域，分别是:',
              '新一代信息技术、手机/通信、先进制造、新能源、环保科技、建筑工程、物联网/安防、生物医药、电子连接器、光电/显示器、居家生活、照明LED、新材料、纺织服装、物流'
            ]
          case 'yantai_all':
          case 'yantai_all_v2':
            return [
              '对烟台市 8,805 家企业绘制产业集群图谱, 为了便于计算和网络图呈现, 仅选择注册资本金大于500万元的企业进入产业集群图谱。图谱中可以较为清晰地看到烟台市的 24 大类产业集群, 分别是:',
              '建筑工程与材料, 食品与农业, 环保科技与新能源, 机械制造 工业自动化, 信息技术, 生物医药, 化工新材 石油化工, 房地产与物业服务, 汽车与汽车配件, 金属材料, 电气与电力工程, 包装印刷, 电子科技与高清显示, 金矿与矿山设备, 医疗器械, 纺织服装, 葡萄酒业, 现代物流, 海洋工程, 资产管理, 光电科技与磁性材料, 航空航天, 文化旅游, 钟表'
            ]
          case 'fuqing_all':
            return [
              '在福清市的产业集群图谱中, 较为清晰地看到 13 大产业集群，分别是:',
              '金属制品/五金制品/塑胶、食品/蔬菜/水产品、贸易/散装食品/包装食品、建设/工程/隧道工程、贸易/针织品/包装食品批发、建材/新材料/家具、房地产开发经营/住房租赁/建筑材料销售、鞋业/纺织/服饰、网络科技/物流/网络技术开发、房屋建筑/建设/工程建设、建设/建筑工程施工总承包/公路工程施工总承包、日用品销售/食品经营/包装食品 和其它'
                  ] 
          case 'special_nanhang_all':
            return [
              '对南方航空集团 409 家企业绘制产业集群图谱,并利用模型对产业集群做自动化识别。选择 "企业相似度大于 70%" 为条件构建企业之间相似的 "边"; 节点大小表示南方航空集团的 "投资金额"',
              '图中可以较为清晰的看到南航集团相关企业可以大致分为 16 个小的业务领域, 分别是 航空租赁、航空信息/票务系统、新能源、金融/资本市场、通用航空服务(客货运输/地面服务)、航空物流、房地产开发、酒店住宿服务、航空传媒、航空器材、航空旅行社、航空工业维修、食品快餐、电子电路、航空保险、以及免税品销售'
            ]
          case 'yixing_all':
            return [
              '在宜兴市的产业集群图谱中, 较为清晰地看到 10 大产业集群，分别是: ',
              '环保科技/环保设备/水处理、电线电缆/电缆/电气机械、特种设备制造/通用设备制造/金属材料销售、建设/工程/建筑工程、化学原料/化工/化工产品、耐火材料/保温材料/耐火材料制品、机械/通用设备制造/通用机械设备、塑料制品/塑业/集装袋、新能源/光电/电子 和其它'
            ]
      
    default:
      return []
  }
}

  