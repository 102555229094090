import { useSigma } from "react-sigma-v2";
import { FC, useEffect } from "react";

import { drawHover } from "../canvas-utils";
import useDebounce from "../use-debounce";

// const NODE_FADE_COLOR = "#bbb";
const NODE_FADE_COLOR = "#CCCCCC";
// const EDGE_FADE_COLOR = "#eee";
const EDGE_SHALLOW_COLOR = '#E7E7E7';
// #F1F2F2, #E7E7E7

const NODE_HIGHLIGHT_COLOR = "#FF3300"; // orange color
const EDGE_HIGHLIGHT_COLOR = "#339900"; // green color

const GraphSettingsController: FC<{ hoveredNode: string | null, showLabel: boolean }> = ({ children, hoveredNode, showLabel }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  // Here we debounce the value to avoid having too much highlights refresh when
  // moving the mouse over the graph:
  const debouncedHoveredNode = useDebounce(hoveredNode, 1); // 40

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    sigma.setSetting("hoverRenderer", (context, data, settings) => {
      drawHover(context, { ...sigma.getNodeDisplayData(data.key), ...data }, settings)
    },
    );
  }, [sigma, graph]);

  /**
   * Update node and edge reducers when a node is hovered, to highlight its
   * neighborhood:
   */
  useEffect(() => {
    // const hoveredColor: string = debouncedHoveredNode ? sigma.getNodeDisplayData(debouncedHoveredNode)!.color : "";

    sigma.setSetting(
      "nodeReducer",
      debouncedHoveredNode
        ? (node, data) => {
          if (node === debouncedHoveredNode) {
            // 高亮 node
            return { ...data, zIndex: 1, color: NODE_HIGHLIGHT_COLOR, highlighted: true }
          } else if (graph.hasEdge(node, debouncedHoveredNode) || graph.hasEdge(debouncedHoveredNode, node)) {
            if (showLabel) {
              return { ...data, zIndex: 1, highlighted: true }
            } else {
              // highlighted: true 显示 label
              return { ...data, zIndex: 1 }
            }
          }
          return { ...data, zIndex: 0, label: "", color: NODE_FADE_COLOR, image: null, highlighted: false }
        }
        : null,
    );
    sigma.setSetting(
      "edgeReducer",
      debouncedHoveredNode
        ? (edge, data) => {
          if (graph.hasExtremity(edge, debouncedHoveredNode)) {
            return { ...data, color: EDGE_HIGHLIGHT_COLOR, size: 1.5 } // 4, yren hover edge width 
          }
          return { ...data, color: EDGE_SHALLOW_COLOR, hidden: true, size: 0.01 }
        }
        : (edge, data) => { return {...data, color: EDGE_SHALLOW_COLOR, size: 0.01 } },
    );
  }, [debouncedHoveredNode, showLabel]);

  return <>{children}</>;
};

export default GraphSettingsController;
