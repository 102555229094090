import { useSigma } from "react-sigma-v2";
import { FC, useEffect } from "react";
import { keyBy, omit } from "lodash";

import { Dataset, FiltersState } from "../types";

const GraphDataController: FC<{ dataset: Dataset; filters: FiltersState }> = ({ dataset, filters, children }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  /**
   * Feed graphology with the new dataset:
   */
  useEffect(() => {
    if (!graph || !dataset) return;

    const clusters = keyBy(dataset.clusters, "key");
    // const tags = keyBy(dataset.tags, "key");

    dataset.nodes.forEach((node) =>
      graph.addNode(node.key, {
        ...node,
        ...omit(clusters[node.cluster], "key"),
        "clusterColor": clusters[node.cluster]["color"] // cluster color
        // image: `${process.env.PUBLIC_URL}/images/${tags[node.tag].image}`,
      }),
    );
    dataset.edges.forEach(([source, target]) => graph.addEdge(source, target, { size: 1 }));

    // Use degrees as node sizes:
    const scores = graph.nodes().map((node) => graph.getNodeAttribute(node, "score"));
    const minDegree = Math.min(...scores);
    const maxDegree = Math.max(...scores);
    
    // score = 0 , set default size 
    if (minDegree === 0 && maxDegree === 0) {
      const defaulSize = 3
      graph.forEachNode((node) =>
      graph.setNodeAttribute(
        node,
        "size",
        defaulSize
      )
      );
    } else {
      let MIN_NODE_SIZE = 3;
      let MAX_NODE_SIZE = 6;
      // 节点数少时, 增加 max size 
      if (dataset.nodes.length < 600) {
        MIN_NODE_SIZE = 4;
        MAX_NODE_SIZE = 12;
      }
      graph.forEachNode((node) =>
        graph.setNodeAttribute(
          node,
          "size",
          ((graph.getNodeAttribute(node, "score") - minDegree) / (maxDegree - minDegree)) *
            (MAX_NODE_SIZE - MIN_NODE_SIZE) +
            MIN_NODE_SIZE,
        ),
      );
    }

    return () => graph.clear();
  }, [graph, dataset]);

  /**
   * Apply filters to graphology:
   */
  useEffect(() => {
    const { clusters, tags, recommendTags, shareholdingTags, investTierTags, provinceTags, potentialGiantTags } = filters;
    graph.forEachNode((node, { cluster, tag, recommendTag, shareholdingTag, investTier, province, potentialGiant }) => {
      graph.setNodeAttribute(node, "mask", !clusters[cluster] || !tags[tag] 
        || (recommendTags && recommendTag && !recommendTags[recommendTag])
        || (shareholdingTags && shareholdingTag && !shareholdingTags[shareholdingTag])
        || (investTierTags && investTier && !investTierTags[investTier])
        || (provinceTags && province && !provinceTags[province])
        || (potentialGiantTags && potentialGiant && !potentialGiantTags[potentialGiant] ))
      const mask = graph.getNodeAttribute(node, "mask")
      if (mask) {
        const NODE_FADE_COLOR = "#CCCCCC";
        graph.setNodeAttribute(node, "color", NODE_FADE_COLOR);
        graph.setNodeAttribute(node, "zIndex", 0)
      } else {
        const clusterColor = graph.getNodeAttribute(node, "clusterColor")
        graph.setNodeAttribute(node, "color", clusterColor)
        graph.setNodeAttribute(node, "zIndex", 1)
      }
    },
    );
  }, [graph, filters]);

  return <>{children}</>;
};

export default GraphDataController;
