import { FC } from "react";
import { BiDownload } from "react-icons/bi";
import { useSigma } from "react-sigma-v2";
import saveAsPNG from "../save-as-png";

const SavePngBtn: FC = () => {
    const layers = ["edges", "nodes", "edgeLabels", "labels", "hovers", "hoverNodes"]
    const sigma = useSigma();

    function handleClick() {
        // console.log('save png handleClick', layers)
        saveAsPNG(sigma, layers)
    }
    return (
        <div className="ico">
            <button
                type="button"
                onClick={ handleClick }
                title="保存图谱 image"
            >
                <BiDownload />
            </button>
        </div>
    );
};

export default SavePngBtn


