import React, { FC } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { getDesc } from "../cluster-util";

import Panel from "./Panel";
import { 
  
 } from "../cluster-util";

const DescriptionPanel: FC<{ fileName: string }> = ({ fileName }) => {
  let desc: string[] = []
  if (fileName) {
    desc = getDesc(fileName)
  }
  return (
    <Panel
      initiallyDeployed
      title={
        <>
          <BsInfoCircle className="text-muted" /> 图谱介绍
        </>
      }
    >
      {desc.map((item, i) => (
        <p key={ i }>
          {item}
        </p>
      ))}
    </Panel>
  );
};

export default DescriptionPanel;
